<template>
  <div class="achievementIndex">
    <!-- 占位路由 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "achievementIndex",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style scoped lang="scss">
.achievementIndex {
  width: 100%;
  height: 100%;
}
</style>
